@font-face {
  font-family: Montserrat-Variable;
  src: url("Montserrat-Variable.31f4c284.woff2") format("woff2"), url("Montserrat-Variable.9fd17944.woff") format("woff"), url("Montserrat-Variable.469edec8.ttf") format("truetype");
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-VariableItalic;
  src: url("Montserrat-VariableItalic.f6af1752.woff2") format("woff2"), url("Montserrat-VariableItalic.9796d1b6.woff") format("woff"), url("Montserrat-VariableItalic.2dc7e1b1.ttf") format("truetype");
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: Montserrat-Thin;
  src: url("Montserrat-Thin.de554ade.woff2") format("woff2"), url("Montserrat-Thin.e99ca653.woff") format("woff"), url("Montserrat-Thin.34275a05.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-ThinItalic;
  src: url("Montserrat-ThinItalic.d3e41ce6.woff2") format("woff2"), url("Montserrat-ThinItalic.5a3948ba.woff") format("woff"), url("Montserrat-ThinItalic.7349ac90.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: Montserrat-ExtraLight;
  src: url("Montserrat-ExtraLight.5818c16f.woff2") format("woff2"), url("Montserrat-ExtraLight.b0f31e0c.woff") format("woff"), url("Montserrat-ExtraLight.ec40c7f7.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-ExtraLightItalic;
  src: url("Montserrat-ExtraLightItalic.1d0fc86d.woff2") format("woff2"), url("Montserrat-ExtraLightItalic.23c67e44.woff") format("woff"), url("Montserrat-ExtraLightItalic.17c3ea39.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: Montserrat-Light;
  src: url("Montserrat-Light.2c587b39.woff2") format("woff2"), url("Montserrat-Light.1faa438d.woff") format("woff"), url("Montserrat-Light.6ba23883.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-LightItalic;
  src: url("Montserrat-LightItalic.b034df1e.woff2") format("woff2"), url("Montserrat-LightItalic.551d5112.woff") format("woff"), url("Montserrat-LightItalic.40a0d7e2.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: Montserrat-Regular;
  src: url("Montserrat-Regular.da7526e6.woff2") format("woff2"), url("Montserrat-Regular.cb18b69d.woff") format("woff"), url("Montserrat-Regular.87125fb4.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-Italic;
  src: url("Montserrat-Italic.94817b05.woff2") format("woff2"), url("Montserrat-Italic.53c64847.woff") format("woff"), url("Montserrat-Italic.708cd6b8.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: Montserrat-Medium;
  src: url("Montserrat-Medium.4249ca3a.woff2") format("woff2"), url("Montserrat-Medium.1c1766c2.woff") format("woff"), url("Montserrat-Medium.446f4a95.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-MediumItalic;
  src: url("Montserrat-MediumItalic.ae034961.woff2") format("woff2"), url("Montserrat-MediumItalic.fe880d89.woff") format("woff"), url("Montserrat-MediumItalic.1ae0bf66.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url("Montserrat-SemiBold.1af012dd.woff2") format("woff2"), url("Montserrat-SemiBold.5ca7328a.woff") format("woff"), url("Montserrat-SemiBold.ac52d8c9.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-SemiBoldItalic;
  src: url("Montserrat-SemiBoldItalic.b8bc5204.woff2") format("woff2"), url("Montserrat-SemiBoldItalic.84499404.woff") format("woff"), url("Montserrat-SemiBoldItalic.f19b4391.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: Montserrat-Bold;
  src: url("Montserrat-Bold.9ff431c7.woff2") format("woff2"), url("Montserrat-Bold.d88a5a75.woff") format("woff"), url("Montserrat-Bold.e32f7dc4.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-BoldItalic;
  src: url("Montserrat-BoldItalic.437c5fc2.woff2") format("woff2"), url("Montserrat-BoldItalic.62ae49f9.woff") format("woff"), url("Montserrat-BoldItalic.4aa45e56.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: Montserrat-ExtraBold;
  src: url("Montserrat-ExtraBold.af428e28.woff2") format("woff2"), url("Montserrat-ExtraBold.61c124fa.woff") format("woff"), url("Montserrat-ExtraBold.2db5627f.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-ExtraBoldItalic;
  src: url("Montserrat-ExtraBoldItalic.393ef7b9.woff2") format("woff2"), url("Montserrat-ExtraBoldItalic.88308928.woff") format("woff"), url("Montserrat-ExtraBoldItalic.db5b224d.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: Montserrat-Black;
  src: url("Montserrat-Black.2dc641d3.woff2") format("woff2"), url("Montserrat-Black.6bb41ae2.woff") format("woff"), url("Montserrat-Black.212e27b8.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-BlackItalic;
  src: url("Montserrat-BlackItalic.aa7a7ff9.woff2") format("woff2"), url("Montserrat-BlackItalic.5fa0c58b.woff") format("woff"), url("Montserrat-BlackItalic.46fb778f.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

/*# sourceMappingURL=index.d7b76afe.css.map */
