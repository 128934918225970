/**
 * @license
 *
 * Font Family: Montserrat
 * Designed by: Julieta Ulanovsky, Sol Matas, Juan Pablo del Peral, Jacques Le Bailly
 * URL: https://www.fontshare.com/fonts/montserrat
 * © 2024 Indian Type Foundry
 *
 * Font Styles:
 * Montserrat Variable(Variable font)
 * Montserrat Variable Italic(Variable font)
 * Montserrat Thin
 * Montserrat Thin Italic
 * Montserrat Extra Light
 * Montserrat Extra Light Italic
 * Montserrat Light
 * Montserrat Light Italic
 * Montserrat Regular
 * Montserrat Italic
 * Montserrat Medium
 * Montserrat Medium Italic
 * Montserrat Semi Bold
 * Montserrat Semi Bold Italic
 * Montserrat Bold
 * Montserrat Bold Italic
 * Montserrat Extra Bold
 * Montserrat Extra Bold Italic
 * Montserrat Black
 * Montserrat Black Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 100.0;
*
* available axes:

* 'wght' (range from 100.0 to 900.0)

*/

@font-face {
  font-family: 'Montserrat-Variable';
  src: url('../fonts/Montserrat-Variable.woff2') format('woff2'),
       url('../fonts/Montserrat-Variable.woff') format('woff'),
       url('../fonts/Montserrat-Variable.ttf') format('truetype');
       font-weight: 100 900;
       font-display: swap;
       font-style: normal;
}


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 100.0;
*
* available axes:

* 'wght' (range from 100.0 to 900.0)

*/

@font-face {
  font-family: 'Montserrat-VariableItalic';
  src: url('../fonts/Montserrat-VariableItalic.woff2') format('woff2'),
       url('../fonts/Montserrat-VariableItalic.woff') format('woff'),
       url('../fonts/Montserrat-VariableItalic.ttf') format('truetype');
       font-weight: 100 900;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Montserrat-Thin';
  src: url('../fonts/Montserrat-Thin.woff2') format('woff2'),
       url('../fonts/Montserrat-Thin.woff') format('woff'),
       url('../fonts/Montserrat-Thin.ttf') format('truetype');
       font-weight: 100;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Montserrat-ThinItalic';
  src: url('../fonts/Montserrat-ThinItalic.woff2') format('woff2'),
       url('../fonts/Montserrat-ThinItalic.woff') format('woff'),
       url('../fonts/Montserrat-ThinItalic.ttf') format('truetype');
       font-weight: 100;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Montserrat-ExtraLight';
  src: url('../fonts/Montserrat-ExtraLight.woff2') format('woff2'),
       url('../fonts/Montserrat-ExtraLight.woff') format('woff'),
       url('../fonts/Montserrat-ExtraLight.ttf') format('truetype');
       font-weight: 200;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Montserrat-ExtraLightItalic';
  src: url('../fonts/Montserrat-ExtraLightItalic.woff2') format('woff2'),
       url('../fonts/Montserrat-ExtraLightItalic.woff') format('woff'),
       url('../fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
       font-weight: 200;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Montserrat-Light';
  src: url('../fonts/Montserrat-Light.woff2') format('woff2'),
       url('../fonts/Montserrat-Light.woff') format('woff'),
       url('../fonts/Montserrat-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Montserrat-LightItalic';
  src: url('../fonts/Montserrat-LightItalic.woff2') format('woff2'),
       url('../fonts/Montserrat-LightItalic.woff') format('woff'),
       url('../fonts/Montserrat-LightItalic.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
       url('../fonts/Montserrat-Regular.woff') format('woff'),
       url('../fonts/Montserrat-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Montserrat-Italic';
  src: url('../fonts/Montserrat-Italic.woff2') format('woff2'),
       url('../fonts/Montserrat-Italic.woff') format('woff'),
       url('../fonts/Montserrat-Italic.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Montserrat-Medium';
  src: url('../fonts/Montserrat-Medium.woff2') format('woff2'),
       url('../fonts/Montserrat-Medium.woff') format('woff'),
       url('../fonts/Montserrat-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Montserrat-MediumItalic';
  src: url('../fonts/Montserrat-MediumItalic.woff2') format('woff2'),
       url('../fonts/Montserrat-MediumItalic.woff') format('woff'),
       url('../fonts/Montserrat-MediumItalic.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
       url('../fonts/Montserrat-SemiBold.woff') format('woff'),
       url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Montserrat-SemiBoldItalic';
  src: url('../fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
       url('../fonts/Montserrat-SemiBoldItalic.woff') format('woff'),
       url('../fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
       url('../fonts/Montserrat-Bold.woff') format('woff'),
       url('../fonts/Montserrat-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Montserrat-BoldItalic';
  src: url('../fonts/Montserrat-BoldItalic.woff2') format('woff2'),
       url('../fonts/Montserrat-BoldItalic.woff') format('woff'),
       url('../fonts/Montserrat-BoldItalic.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
       url('../fonts/Montserrat-ExtraBold.woff') format('woff'),
       url('../fonts/Montserrat-ExtraBold.ttf') format('truetype');
       font-weight: 800;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Montserrat-ExtraBoldItalic';
  src: url('../fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
       url('../fonts/Montserrat-ExtraBoldItalic.woff') format('woff'),
       url('../fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
       font-weight: 800;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Montserrat-Black';
  src: url('../fonts/Montserrat-Black.woff2') format('woff2'),
       url('../fonts/Montserrat-Black.woff') format('woff'),
       url('../fonts/Montserrat-Black.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Montserrat-BlackItalic';
  src: url('../fonts/Montserrat-BlackItalic.woff2') format('woff2'),
       url('../fonts/Montserrat-BlackItalic.woff') format('woff'),
       url('../fonts/Montserrat-BlackItalic.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: italic;
}

